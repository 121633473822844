import { Link } from 'react-router-dom';
import CarPng from '../assest/dorado.jpg';
import Image1 from '../assest/calle_b.jpg';
import Image2 from '../assest/calle_bb.jpg';
import Image3 from '../assest/calle_pasillo_bogotá.jpg';
import "./Nosotros.css"
const Nosotros = () => {
  return (
    <section id='nosotros' className="container">
      <div className="content">
      <div className="gallery-container">
        <img src={Image1} alt="Dominar 400 en Bogotá - 4Rent" className="gallery-image" />
        <img src={Image2} alt="Calle hermosa en Bogotá - 4Rent" className="gallery-image" />
        <img src={Image3} alt="Calle hermosa de paraguas en Bogotá - 4Rent" className="gallery-image" />
      </div>
        <div className="text-container">
          <div className="text-content">
            <h2 className="title">
            Explora 4Rent, la agencia líder en alquiler de motos en Bogotá.
            </h2>
            <p className="subtitle">
            La opción más destacada para el alquiler de motos en Bogotá: ¡4Rent!
            </p>
            <p className="description">
              Bienvenido a 4Rent, la agencia de alquiler de motos ubicada estratégicamente en Engativá. Ofrecemos una amplia selección de motocicletas automáticas y manuales para que experimentes Bogotá sobre dos ruedas. Si buscas aventuras más allá de la ciudad, también disponemos de motos de alto cilindraje para que explores todo el país. ¡Te invitamos a visitar nuestra oficina en Bogotá! Nuestros asesores están preparados para atenderte en cualquier momento, ya que nuestras líneas están disponibles las 24 horas del día, los 7 días de la semana. ¡No pierdas más tiempo y descubre en 4Rent el alquiler de moto que te permitirá disfrutar plenamente del poder de viajar en dos ruedas por Bogotá, Colombia!
            </p>
          </div>
        </div>
      </div>
      <div className="image-container">
          <img
            src={CarPng}
            alt="Bogotá 4Rent - El Dorado - Moto Dominar 400"
            className="car-image"
          />
        </div>
        <p className="link">
              <Link to="/motocicletas" className="button">
                Ver motos disponibles
              </Link>
            </p>
    </section>
  );
};

export default Nosotros;
