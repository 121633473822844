// src/components/PayUForm.jsx

import React, { useState } from 'react';
import axios from 'axios';
import displayINRCurrency from '../helpers/displayCurrency';
import SummaryApi from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../store/cartSlice';
import { useNavigate } from 'react-router-dom';

const PayUForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems);

  // Estados para el formulario de pago
  const [currency] = useState('COP'); // Moneda predeterminada
  const [referenceCode, setReferenceCode] = useState('');
  const [description, setDescription] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');

  // Calcular el total del carrito
  const calculateTotal = () => {
    if (!Array.isArray(cartItems)) {
      console.error('cartItems no es un array:', cartItems);
      return 0;
    }

    let total = 0;
    cartItems.forEach((item) => {
      if (item && item.reservationDetails && typeof item.reservationDetails.totalPrice === 'number') {
        total += item.reservationDetails.totalPrice;
      } else {
        console.warn('Item inválido en cartItems:', item);
      }
    });
    return total;
  };

  const totalAmount = calculateTotal();
  const paymentAmount = totalAmount * 0.10; // 10% del total

  // Función para manejar el pago
  const handlePayment = async (e) => {
    e.preventDefault();

    // Validaciones básicas
    if (!referenceCode || !buyerEmail) {
      alert('Por favor, completa los campos de referencia y correo electrónico.');
      return;
    }

    try {
      const paymentData = {
        amount: paymentAmount,
        currency,
        referenceCode,
        description,
        buyerEmail,
      };

      // Enviar la solicitud de pago al backend
      const response = await axios.post(SummaryApi.payments.pay.url, paymentData);

      // Manejar la respuesta del pago
      if (response.data.state === 'APPROVED') {
        // Enviar los detalles de la reserva al backend
        await sendReservationDetailsToBackend();

        // Limpiar el carrito
        dispatch(clearCart());

        // Redirigir a la página de confirmación de pago
        navigate('/payment-success');
      } else {
        // Mostrar mensaje de error si el pago no fue aprobado
        alert('El pago no fue aprobado. Inténtalo de nuevo.');
      }
    } catch (error) {
      console.error("Error al procesar el pago:", error);
      alert('Hubo un error al procesar tu pago.');
    }
  };

  // Función para enviar los detalles de la reserva al backend después del pago
  const sendReservationDetailsToBackend = async () => {
    try {
      // Asumimos que el backend espera un array de items
      const payload = {
        cartItems: cartItems.map((item) => ({
          productId: item.productId,
          reservationDetails: item.reservationDetails,
        })),
      };

      const response = await axios.post(SummaryApi.addToCartProduct.url, payload, {
        withCredentials: true,
      });

      if (response.data.success) {
        console.log('Reservas enviadas al backend con éxito.');
      } else {
        console.error('Error al enviar las reservas al backend:', response.data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud al backend:', error);
    }
  };

  return (
    <div className="payment-form">
      <h2>Pagar con PayU</h2>
      <form onSubmit={handlePayment}>
        <div className="form-group">
          <label>Monto a Pagar (10% del total):</label>
          <input
            type="number"
            value={displayINRCurrency(paymentAmount)}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Descripción:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Referencia:</label>
          <input
            type="text"
            value={referenceCode}
            onChange={(e) => setReferenceCode(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Correo del comprador:</label>
          <input
            type="email"
            value={buyerEmail}
            onChange={(e) => setBuyerEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Pagar</button>
      </form>
      <p className="payment-note">Se paga el 10% del total por reserva.</p>
    </div>
  );
};

export default PayUForm;
