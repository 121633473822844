// src/pages/ProductDetails.js

import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SummaryApi from '../common';
import displayINRCurrency from '../helpers/displayCurrency';
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import {
  TbCircuitMotor,
  TbCylinderPlus,
  TbCarCrane,
} from 'react-icons/tb';
import { AiOutlineSisternode } from 'react-icons/ai';
import { SlEnergy } from 'react-icons/sl';
import {
  GiGasPump,
  GiHorseHead,
  GiSpeedometer,
  GiFullMotorcycleHelmet,
  GiLifeJacket,
  GiColombia,
} from 'react-icons/gi';
import { CgChanel } from 'react-icons/cg';
import { SiVirginmedia } from 'react-icons/si';
import { FaBoxArchive } from 'react-icons/fa6';
import { IoPhonePortraitSharp } from 'react-icons/io5';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { MdPermPhoneMsg } from 'react-icons/md';

import './ProductDetails.css';

import { useDispatch } from 'react-redux';
import { addToCart } from '../store/cartSlice'; // Ruta corregida

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: '',
    brandName: '',
    category: '',
    productImage: [],
    description: '',
    price: '',
    sellingPrice: '',
    motor: '',
    cilindrada: '',
    alimentacion: '',
    diametroXCarrera: '',
    potencia: '',
    torqueMaximo: '',
    arranque: '',
    capacidadCombustible: '',
    transmision: '',
    maletero: '',
    baulCapacidad: '',
    planesTarifas: {
      alquilerPorDias: [],
      planesSemanales: [],
      planesQuincenales: [],
      planesMensuales: [],
      adicionales: {
        kilometroAdicionalCOP: 0,
        horaAdicionalCOP: 0,
        depositoFianzaCOP: 0,
      },
    },
  });

  const params = useParams();
  const [loading, setLoading] = useState(true);
  const productImageListLoading = new Array(4).fill(null);
  const [activeImage, setActiveImage] = useState('');
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 });
  const [zoomImage, setZoomImage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Estado para el formulario de reserva
  const [reservationDetails, setReservationDetails] = useState({
    planType: '',
    planOption: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    additionalProducts: [],
    city: '', // Nuevo campo para la ciudad
  });

  // Estado para el total
  const [totalPrice, setTotalPrice] = useState(0);

  // Lista de productos adicionales
  const additionalProductsList = [
    { name: 'Impermeable', price: 15000, unit: 'COP', perDay: false },
    { name: 'Servicio de lavado', price: 15000, unit: 'COP', perDay: false },
    { name: 'Abrigo o jacket', price: 9000, unit: 'COP', perDay: false },
    { name: 'Guantes (Gloves)', price: 4000, unit: 'COP', perDay: false },
    { name: 'Rodilleras', price: 5000, unit: 'COP', perDay: false },
    { name: 'Gopro 9', price: 39000, unit: 'COP', perDay: false },
    { name: 'Insta 360 x3', price: 79000, unit: 'COP', perDay: false },
    { name: 'Entrega domicilio', price: 39000, unit: 'COP', perDay: false },
    { name: 'Devolución domicilio', price: 39000, unit: 'COP', perDay: false },
    { name: 'Entrega domicilio', price: 59000, unit: 'COP', perDay: false },
    { name: 'Devolución domicilio', price: 59000, unit: 'COP', perDay: false },
  ];

  // Obtener detalles del producto desde la API
  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.productDetails.url, {
        method: SummaryApi.productDetails.method,
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          productId: params?.id,
        }),
      });
      const dataResponse = await response.json();
      setLoading(false);
      setData(dataResponse?.data || {}); // Manejar datos undefined
      setActiveImage(dataResponse?.data?.productImage[0] || ''); // Manejar undefined
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);

  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL);
  };

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    setZoomImageCoordinate({ x, y });
  }, []);

  const handleLeaveImageZoom = () => {
    setZoomImage(false);
  };

  // Manejar cambios en el formulario de reserva
  const handleReservationChange = (e) => {
    const { name, value } = e.target;
    setReservationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [reservationDetails, data]);

  // Manejar selección de productos adicionales
  const handleAdditionalProductChange = (e) => {
    const { name, checked } = e.target;
    setReservationDetails((prevDetails) => {
      let updatedAdditionalProducts = [...prevDetails.additionalProducts];

      if (checked) {
        updatedAdditionalProducts.push(name);
      } else {
        updatedAdditionalProducts = updatedAdditionalProducts.filter((item) => item !== name);
      }

      return {
        ...prevDetails,
        additionalProducts: updatedAdditionalProducts,
      };
    });
  };

  // Calcular el precio total
  const calculateTotalPrice = () => {
    let total = 0;

    // Obtener el precio del plan seleccionado
    if (reservationDetails.planType && reservationDetails.planOption) {
      let planPrice = 0;

      const planes = data.planesTarifas || {};

      if (reservationDetails.planType === 'alquilerPorDias') {
        const selectedPlan = planes.alquilerPorDias?.find(
          (plan) => plan.dias === reservationDetails.planOption
        );
        planPrice = selectedPlan ? parseFloat(selectedPlan.tarifaCOP) : 0;
      } else if (reservationDetails.planType === 'planesSemanales') {
        const selectedPlan = planes.planesSemanales?.find(
          (plan) => plan.kilometros === reservationDetails.planOption
        );
        planPrice = selectedPlan ? parseFloat(selectedPlan.tarifaCOP) : 0;
      } else if (reservationDetails.planType === 'planesQuincenales') {
        const selectedPlan = planes.planesQuincenales?.find(
          (plan) => plan.kilometros === reservationDetails.planOption
        );
        planPrice = selectedPlan ? parseFloat(selectedPlan.tarifaCOP) : 0;
      } else if (reservationDetails.planType === 'planesMensuales') {
        const selectedPlan = planes.planesMensuales?.find(
          (plan) => plan.kilometros === reservationDetails.planOption
        );
        planPrice = selectedPlan ? parseFloat(selectedPlan.tarifaCOP) : 0;
      }

      total += planPrice;
    }

    // Agregar el costo de los productos adicionales
    reservationDetails.additionalProducts.forEach((productName) => {
      const product = additionalProductsList.find((item) => item.name === productName);
      if (product) {
        if (product.perDay) {
          // Calcular la cantidad de días
          const startDateTime = new Date(`${reservationDetails.startDate}T${reservationDetails.startTime}`);
          const endDateTime = new Date(`${reservationDetails.endDate}T${reservationDetails.endTime}`);

          if (isNaN(startDateTime) || isNaN(endDateTime)) {
            return;
          }

          // Diferencia en milisegundos
          const timeDiff = endDateTime - startDateTime;

          // Convertir a horas
          const hours = timeDiff / (1000 * 3600);

          // Si la diferencia es menor o igual a 24 horas, contar como 1 día
          // De lo contrario, calcular el número de días (redondeando hacia arriba)
          const days = hours <= 24 ? 1 : Math.ceil(hours / 24);

          total += product.price * days;
        } else {
          total += product.price;
        }
      }
    });

    setTotalPrice(total);
  };

  // Manejar envío del formulario de reserva
  const handleReservationSubmit = (e) => {
    e.preventDefault();

    // Validaciones necesarias
    if (
      !reservationDetails.planType ||
      !reservationDetails.startDate ||
      !reservationDetails.endDate ||
      !reservationDetails.startTime ||
      !reservationDetails.endTime ||
      !reservationDetails.planOption ||
      !reservationDetails.city // Validar que la ciudad esté seleccionada
    ) {
      alert('Por favor, completa todos los campos de la reserva.');
      return;
    }

    const reservationInfo = {
      productName: data.productName,
      productImage: data.productImage[0] || '', // Manejar undefined
      category: data.category || '',
      planType: reservationDetails.planType,
      planOption: reservationDetails.planOption,
      startDate: reservationDetails.startDate,
      startTime: reservationDetails.startTime,
      endDate: reservationDetails.endDate,
      endTime: reservationDetails.endTime,
      additionalProducts: reservationDetails.additionalProducts,
      totalPrice,
      city: reservationDetails.city || 'Ciudad no especificada',
    };

    // Agregar al carrito en Redux
    dispatch(addToCart({ productId: data._id, reservationDetails: reservationInfo }));

    // Navegar al carrito
    navigate('/cart');
  };

  return (
    <div className="product-details-container">
      <div className="product-details-wrapper">
        {/* Imagen del Producto y Productos Adicionales */}
        <div className="left-section">
          <div className="product-images">
            <div className="product-main-image" onMouseLeave={handleLeaveImageZoom}>
              <img
                src={activeImage || 'ruta/por/defecto.jpg'}
                className="main-image"
                onMouseMove={handleZoomImage}
                alt={data?.productName || 'Producto'}
              />
              {/* Zoom de la Imagen */}
              {zoomImage && activeImage && (
                <div className="zoomed-image">
                  <div
                    className="zoomed-content"
                    style={{
                      backgroundImage: `url(${activeImage})`,
                      backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="product-thumbnails">
              {loading ? (
                productImageListLoading.map((_, index) => (
                  <div className="thumbnail-loading" key={'loadingImage' + index} />
                ))
              ) : (
                data?.productImage?.map((imgURL, index) => (
                  <img
                    src={imgURL}
                    className="thumbnail-image"
                    key={index}
                    onMouseEnter={() => handleMouseEnterProduct(imgURL)}
                    onClick={() => handleMouseEnterProduct(imgURL)}
                    alt={`Thumbnail ${index + 1}`}
                  />
                ))
              )}
            </div>
          </div>

          {/* Productos Adicionales debajo de las imágenes */}
          <div className="additional-products">
            <h4>Productos Adicionales</h4>
            {additionalProductsList.map((product, index) => (
              <div key={index} className="additional-product-item">
                <label style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <input
                    type="checkbox"
                    name={product.name}
                    onChange={handleAdditionalProductChange}
                    checked={reservationDetails.additionalProducts.includes(product.name)}
                  />
                  {product.name}: {displayINRCurrency(product.price)} {product.unit}
                  {product.perDay ? '/día' : ''}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Detalles del Producto y Formulario de Reserva */}
        {loading ? (
          <div className="product-info-loading">
            {/* Puedes agregar un skeleton loader aquí */}
            <p>Cargando detalles del producto...</p>
          </div>
        ) : (
          <div className="right-section">
            <div className="product-info">
              <p className="brand-name">{data?.brandName || 'Marca desconocida'}</p>
              <h2 className="product-title">{data?.productName || 'Producto'}</h2>
              <p className="product-category">{data?.category || 'Categoría desconocida'}</p>
              <div className="product-pricing">
                <p className="selling-price">
                  {displayINRCurrency(data.sellingPrice || 0)}
                </p>
                <p className="original-price">{displayINRCurrency(data.price || 0)}</p>
              </div>
            </div>

            {/* Formulario de reserva */}
            <div className="reservation-form">
              <h3>Reserva tu plan</h3>
              <form onSubmit={handleReservationSubmit}>
                <div className="form-group">
                  <label htmlFor="planType">Tipo de Plan:</label>
                  <select
                    id="planType"
                    name="planType"
                    value={reservationDetails.planType}
                    onChange={handleReservationChange}
                    required
                  >
                    <option value="">Seleccione un plan</option>
                    {data.planesTarifas?.alquilerPorDias?.length > 0 && (
                      <option value="alquilerPorDias">Alquiler por Días</option>
                    )}
                    {data.planesTarifas?.planesSemanales?.length > 0 && (
                      <option value="planesSemanales">Planes Semanales</option>
                    )}
                    {data.planesTarifas?.planesQuincenales?.length > 0 && (
                      <option value="planesQuincenales">Planes Quincenales</option>
                    )}
                    {data.planesTarifas?.planesMensuales?.length > 0 && (
                      <option value="planesMensuales">Planes Mensuales</option>
                    )}
                  </select>
                </div>

                {/* Opciones según el plan seleccionado */}
                {reservationDetails.planType && (
                  <div className="form-group">
                    <label htmlFor="planOption">Opciones del Plan:</label>
                    <select
                      id="planOption"
                      name="planOption"
                      value={reservationDetails.planOption}
                      onChange={handleReservationChange}
                      required
                    >
                      <option value="">Seleccione una opción</option>
                      {reservationDetails.planType === 'alquilerPorDias' &&
                        data.planesTarifas?.alquilerPorDias?.map((plan, index) => (
                          <option key={index} value={plan.dias}>
                            {plan.dias} días - {displayINRCurrency(plan.tarifaCOP)} COP
                          </option>
                        ))}
                      {reservationDetails.planType === 'planesSemanales' &&
                        data.planesTarifas?.planesSemanales?.map((plan, index) => (
                          <option key={index} value={plan.kilometros}>
                            {plan.kilometros} km - {displayINRCurrency(plan.tarifaCOP)} COP
                          </option>
                        ))}
                      {reservationDetails.planType === 'planesQuincenales' &&
                        data.planesTarifas?.planesQuincenales?.map((plan, index) => (
                          <option key={index} value={plan.kilometros}>
                            {plan.kilometros} km - {displayINRCurrency(plan.tarifaCOP)} COP
                          </option>
                        ))}
                      {reservationDetails.planType === 'planesMensuales' &&
                        data.planesTarifas?.planesMensuales?.map((plan, index) => (
                          <option key={index} value={plan.kilometros}>
                            {plan.kilometros} km - {displayINRCurrency(plan.tarifaCOP)} COP
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {/* Selección de ciudad */}
                <div className="form-group">
                  <label htmlFor="city">Ciudad:</label>
                  <select
                    id="city"
                    name="city"
                    value={reservationDetails.city}
                    onChange={handleReservationChange}
                    required
                  >
                    <option value="">Seleccione una ciudad</option>
                    <option value="Medellin">Medellín</option>
                    <option value="Bogota">Bogotá</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="startDate">Fecha de Inicio:</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={reservationDetails.startDate}
                    onChange={handleReservationChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="startTime">Hora de Inicio:</label>
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={reservationDetails.startTime}
                    onChange={handleReservationChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="endDate">Fecha de Fin:</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={reservationDetails.endDate}
                    onChange={handleReservationChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="endTime">Hora de Entrega:</label>
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={reservationDetails.endTime}
                    onChange={handleReservationChange}
                    required
                  />
                </div>

                {/* Mostrar el precio total */}
                <div className="total-price">
                  <p>Total: {displayINRCurrency(totalPrice)} COP</p>
                </div>

                <div className="form-buttons">
                  <button type="submit" className="reserve-button">
                    Reservar
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      {/* Sección de Tarifas */}
      {!loading && data?.planesTarifas && (
        <div className="tarifas-section">
          <h2 className="tarifas-title">Planes y Tarifas</h2>

          {/* Alquiler por Días */}
          {data.planesTarifas.alquilerPorDias?.length > 0 && (
            <div className="tarifas-plan">
              <h3>Alquiler por Días</h3>
              <table className="tarifas-table">
                <thead>
                  <tr>
                    <th>Días</th>
                    <th>Tarifa COP</th>
                    <th>Tarifa USD</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.alquilerPorDias.map((plan, index) => (
                    <tr key={index}>
                      <td>{plan.dias}</td>
                      <td>{displayINRCurrency(plan.tarifaCOP)}</td>
                      <td>{displayINRCurrency(plan.tarifaUSD)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Planes Semanales */}
          {data.planesTarifas.planesSemanales?.length > 0 && (
            <div className="tarifas-plan">
              <h3>Planes Semanales</h3>
              <table className="tarifas-table">
                <thead>
                  <tr>
                    <th>Kilómetros</th>
                    <th>Tarifa COP</th>
                    <th>Tarifa USD</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.planesSemanales.map((plan, index) => (
                    <tr key={index}>
                      <td>{plan.kilometros}</td>
                      <td>{displayINRCurrency(plan.tarifaCOP)}</td>
                      <td>{displayINRCurrency(plan.tarifaUSD)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Planes Quincenales */}
          {data.planesTarifas.planesQuincenales?.length > 0 && (
            <div className="tarifas-plan">
              <h3>Planes Quincenales</h3>
              <table className="tarifas-table">
                <thead>
                  <tr>
                    <th>Kilómetros</th>
                    <th>Tarifa COP</th>
                    <th>Tarifa USD</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.planesQuincenales.map((plan, index) => (
                    <tr key={index}>
                      <td>{plan.kilometros}</td>
                      <td>{displayINRCurrency(plan.tarifaCOP)}</td>
                      <td>{displayINRCurrency(plan.tarifaUSD)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Planes Mensuales */}
          {data.planesTarifas.planesMensuales?.length > 0 && (
            <div className="tarifas-plan">
              <h3>Planes Mensuales</h3>
              <table className="tarifas-table">
                <thead>
                  <tr>
                    <th>Kilómetros</th>
                    <th>Tarifa COP</th>
                    <th>Tarifa USD</th>
                  </tr>
                </thead>
                <tbody>
                  {data.planesTarifas.planesMensuales.map((plan, index) => (
                    <tr key={index}>
                      <td>{plan.kilometros}</td>
                      <td>{displayINRCurrency(plan.tarifaCOP)}</td>
                      <td>{displayINRCurrency(plan.tarifaUSD)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Adicionales */}
          <div className="tarifas-adicionales">
            <h3>Adicionales</h3>
            <ul>
              <li>
                Kilómetro Adicional: {displayINRCurrency(data.planesTarifas.adicionales.kilometroAdicionalCOP)} COP
              </li>
              <li>
                Hora Adicional: {displayINRCurrency(data.planesTarifas.adicionales.horaAdicionalCOP)} COP
              </li>
              <li>
                Depósito/Fianza: {displayINRCurrency(data.planesTarifas.adicionales.depositoFianzaCOP)} COP
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Productos Relacionados */}
      <h2 className="related-products-title">Otras Motos</h2>
      <CategroyWiseProductDisplay
        category={data?.category}
        currentProductId={data?._id}
      />

      {/* Especificaciones Técnicas */}
      <div className="banner">
        <h3 className="banner-title">Especificaciones Técnicas</h3>
      </div>
      <div className="specifications-table-container">
        <table className="specifications-table">
          <tbody>
            <tr>
              <td>
                <TbCircuitMotor />
              </td>
              <th>Motor:</th>
              <td>{data.motor || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <TbCylinderPlus />
              </td>
              <th>Cilindrada:</th>
              <td>{data.cilindrada || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <AiOutlineSisternode />
              </td>
              <th>Alimentación:</th>
              <td>{data.alimentacion || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <SiVirginmedia />
              </td>
              <th>Diámetro x Carrera:</th>
              <td>{data.diametroXCarrera || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <GiHorseHead />
              </td>
              <th>Potencia:</th>
              <td>{data.potencia || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <CgChanel />
              </td>
              <th>Torque Máximo:</th>
              <td>{data.torqueMaximo || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <SlEnergy />
              </td>
              <th>Arranque:</th>
              <td>{data.arranque || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <GiGasPump />
              </td>
              <th>Capacidad de Combustible:</th>
              <td>{data.capacidadCombustible || 'N/A'}</td>
            </tr>
            <tr>
              <td>
                <GiSpeedometer />
              </td>
              <th>Transmisión:</th>
              <td>{data.transmision || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Lo incluido dentro de las tarifas */}
      <div className="banner">
        <h3 className="banner-title">Lo incluido dentro de las tarifas</h3>
      </div>
      <div className="inclusions-table-container">
        <table className="inclusions-table">
          <thead>
            <tr>
              <th>Elemento</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <GiFullMotorcycleHelmet className="icon" /> Cascos
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <GiLifeJacket className="icon" /> Chalecos reflectivos
              </td>
              <td>2</td>
            </tr>
            {data.maletero === 'Sí' && (
              <tr>
                <td>
                  <FaBoxArchive className="icon" /> Baúl de {data.baulCapacidad}
                </td>
                <td>1</td>
              </tr>
            )}
            <tr>
              <td>
                <IoPhonePortraitSharp className="icon" /> Soporte para celular en aluminio
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <RiSecurePaymentFill className="icon" /> Seguro contra robo (Deducible del 15%)
              </td>
              <td>✔</td>
            </tr>
            <tr>
              <td>
                <MdPermPhoneMsg className="icon" /> Asistencia Telefónica 24/7 (Emergencias)
              </td>
              <td>✔</td>
            </tr>
            <tr>
              <td>
                <GiColombia className="icon" /> Mantenimientos Incluidos a nivel Nacional
              </td>
              <td>✔</td>
            </tr>
            <tr>
              <td>
                <RiSecurePaymentFill className="icon" /> Seguro contra pérdidas totales (Deducible del 15%)
              </td>
              <td>✔</td>
            </tr>
            <tr>
              <td>
                <TbCarCrane className="icon" /> Grúa 24/7 a nivel nacional (Problemas mecánicos o accidente)
              </td>
              <td>✔</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Sección Final */}
      <div className="final-section">
        <h2 className="final-section-title">
          ¡Listo para desatar la emoción sobre dos ruedas!
        </h2>
        <div className="final-section-content">
          <a href="/contacto" className="final-section-button">
            ¡Comunícate por WhatsApp!
          </a>
          <p className="final-section-text">{data?.description || 'Descripción del producto no disponible.'}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
