import { useEffect, useState } from 'react';
import { FaInstagram, FaLocationArrow, FaMap, FaMobileAlt, FaTiktok } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Footer.css'; 
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';

const Footer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para obtener los productos de la categoría 'Motos'
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoryProduct = await fetchCategoryWiseProduct('Motos');
        if (categoryProduct && categoryProduct.data) {
          setData(categoryProduct.data);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error('Error al obtener los productos:', error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Enlaces del footer
  const FooterLinks = [
    { title: "Home", link: "/#alquilerdemotos", id: "alquilerdemotos" },
    { title: "Nosotros", link: "/nosotros#nosotros", id: "nosotros" },
    { title: "Contacto", link: "/contacto#contacto", id: "contacto" },
  ];

  const FooterLinks3 = [
    { title: "Rentar", link: "/rentar#rentar", id: "rentar" },
    { title: "Condiciones 4Rent", link: "/condiciones4rent#condiciones", id: "condiciones" },
    { title: "Requisitos 4Rent", link: "/requisitos4rent#requisitos", id: "requisitos" },
  ];

  // Función para hacer scroll a una sección específica
  const handleScrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer-container">
      <section className="footer-section">
        <div className="footer-grid">
          {/* Columna de información general */}
          <div className="footer-column">
            <h2 className="footer-title">Rentar una moto</h2>
            <p className="footer-description">Descubre la libertad en cada aventura. ¡En 4rent, tus sueños toman el control!</p>
            <div className="footer-item">
              <FaLocationArrow />
              <p> Bogotá y Medellín</p>
            </div>
            <div className="footer-item">
              <div className="footer-items-lineal">
                <p><FaMap /> Calle 86 # 87A - 04 Engativá, Bogotá D.C</p>
                <p><FaMap /> Carrera 48 #86 - 61, San Fernando, Itagüí. Antioquia</p>
              </div>
            </div>
            <div className="footer-item">
              <FaMobileAlt />
              <p> +57 315 4573482</p>
            </div>
            <div className="footer-item">
              <MdEmail />
              <a href="mailto:reservas@4rent.com.co"><p>reservas@4rent.com.co</p></a>
            </div>
          </div>
          {/* Columna de enlaces de información */}
          <div className="footer-column">
            <h2 className="footer-title">Información</h2>
            <ul className="footer-links-list">
              {FooterLinks.map((link, index) => (
                <li key={index} className="footer-link-item">
                  <Link to={link.link} onClick={() => handleScrollToSection(link.id)} className="footer-link">
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* Columna de modelos (motos) */}
          <div className="footer-column">
            <h2 className="footer-title">Modelos</h2>
            <ul className="footer-links-list">
              {loading ? (
                <li className="footer-link-item">Cargando...</li>
              ) : data.length > 0 ? (
                data.map((product) => (
                  <li key={product._id} className="footer-link-item">
                    <Link to={`/product/${product._id}`} className="footer-link">
                      {product.productName}
                    </Link>
                  </li>
                ))
              ) : (
                <li className="footer-link-item">No hay productos</li>
              )}
            </ul>
          </div>
          {/* Columna de servicios */}
          <div className="footer-column">
            <h2 className="footer-title">Servicios</h2>
            <ul className="footer-links-list">
              {FooterLinks3.map((link, index) => (
                <li key={index} className="footer-link-item">
                  <Link to={link.link} onClick={() => handleScrollToSection(link.id)} className="footer-link">
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* Sección de íconos sociales */}
      <section>
        <div className="footer-icons">
          <a 
            href="https://www.tiktok.com/@4rent.colombia?lang=es" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Tiktok de 4rent"
            className="footer-icon-item"
          >
            <FaTiktok />
            <p>Tiktok</p>
          </a>
          <a 
            href="https://www.instagram.com/4rent.col/" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Instagram de 4rent"
            className="footer-icon-item"
          >
            <FaInstagram />
            <p>Instagram</p>
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
