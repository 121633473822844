// src/redux/cartSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [], // Renombrado para coincidir con los selectores
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // Agrega un producto al carrito
      state.cartItems.push(action.payload);
    },
    removeFromCart: (state, action) => {
      // Elimina un producto por su ID
      state.cartItems = state.cartItems.filter(
        (item) => item.productId !== action.payload.productId
      );
    },
    clearCart: (state) => {
      // Limpia el carrito
      state.cartItems = [];
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
