import PropTypes from 'prop-types';
import './Contacto.css';
import { FaMobileAlt } from "react-icons/fa";
import { FaWhatsapp } from 'react-icons/fa6';

const Contacto = ({ phoneNumberone }) => {
  const openWhatsAppone = () => {
    window.open(`https://wa.me/${phoneNumberone}`, '_blank');
  };

  return (
    <>
      <section id='contacto' className="contact-section">
        <div className="contact-content">
          <div className="contact-container">
            <div className="contact-grid">
              <div className="contact-description">
                <h1 className="contact-title">
                  ¡Descubre Bogotá en Moto y Vive una Aventura Inolvidable!
                </h1>
                <p className="contact-text">
                  ¿Estás listo para explorar Bogotá de una manera emocionante y única? Alquila una motocicleta con nosotros y descubre cada hermosa ciudad colombiana.
                </p>
              </div>
              <div className="contact-info">
                <p className="help-text">
                  ¿Necesitas ayuda?
                </p>
                <p className="contact-social">
                  <a href={`tel:${phoneNumberone}`} className="phone-link">
                    <FaMobileAlt /><p> {phoneNumberone}</p> 
                  </a>
                </p>
                <p className="help-text">
                  <a onClick={openWhatsAppone} className="phone-link">
                    <FaWhatsapp /><p> {phoneNumberone}</p> 
                  </a>
                </p>
                <p className="hours-title">Horarios:</p>
                <ul className="hours-list">
                  <li className='hours-item'>Lunes a Viernes: 8:00 am - 6:00 pm</li>
                  <li className='hours-item'>Sábado: 8:00 am - 2:00 pm</li>
                  <li className='hours-item'>Domingo y festivos: 5:00 pm - 7:00 pm</li>
                  <li className='hours-item'>Trabajamos 24/7 con servicio a domicilio</li>
                </ul>
                <button className="contact-button" onClick={openWhatsAppone}>
                  Llámanos
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='contact-form-section' className="contact-form-section">
        <div className="contact-content_one">
          <h2 className="form-title">Contáctanos</h2>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit" className="form-button">Enviar</button>
          </form>
        </div>
      </section>

      <section id='map-section'>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.3552119128412!2d-74.10143342429066!3d4.708211241590882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e44299987c5baa9%3A0xad3b08d9dc2a4797!2s4Rent%20Colombia%20-%20Alquiler%20de%20motos%20en%20Bogot%C3%A1%20y%20Medell%C3%ADn%20(Motorcycle%20%2F%20Scooter%20Rental)!5e0!3m2!1ses!2sco!4v1712362878738!5m2!1ses!2sco"
            className="contact-map"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

Contacto.propTypes = {
  phoneNumberone: PropTypes.string.isRequired,
};

export default Contacto;
