
import './StepsComponent.css';

const stepsData = [
  { number: 1, text: "Descubre tu Moto Ideal", description: "Haz clic en 'Motos' y descubre nuestra increíble selección. Escoge entre nuestras motocicletas manuales y automáticas para encontrar la que mejor se adapte a tu estilo, ubicación y fechas disponibles." },
  { number: 2, text: "Haz tu Reserva", description: "Selecciona la moto que te inspire y realiza la reserva. Completa tus datos y comienza tu aventura en Bogotá." },
  { number: 3, text: "Recoge tu Moto", description: "Antes de salir, asegúrate de revisar el estado de la moto y toma nota de cualquier detalle. Si optas por la entrega a domicilio, captura algunas fotos del estado de la moto." },
  { number: 4, text: "Disfruta de la Libertad", description: "Déjate llevar por el viento y la sensación de libertad. Experimenta el placer de viajar en moto por las calles de Bogotá." },
  { number: 5, text: "Vive la Aventura", description: "Explora Bogotá como nunca antes lo has hecho. Descubre nuevos lugares y vive experiencias únicas mientras recorres la ciudad sobre dos ruedas." },
  { number: 6, text: "Comparte tu Historia", description: "Al finalizar tu viaje, comparte tus memorias y fotos en nuestras redes sociales. Inspira a otros viajeros a explorar Bogotá con 4 Rent y forma parte de nuestra comunidad de aventureros en moto. ¡Tu historia puede ser la próxima en ser contada!" },
  { number: 7, text: "Devuelve la Moto", description: "Cuando tu aventura llegue a su fin, devuelve la moto en el lugar y hora acordados. Asegúrate de entregarla en las mismas condiciones en las que la recibiste para evitar cargos adicionales." },
  { number: 8, text: "Evalúa tu Experiencia", description: "¡Queremos saber qué tal te fue! Después de devolver la moto, tómate un momento para evaluar tu experiencia con nosotros. Tu opinión nos ayuda a mejorar nuestros servicios." },
  { number: 9, text: "Mantente en Contacto", description: "No te pierdas nuestras ofertas especiales y novedades. Mantente en contacto con nosotros a través de nuestras redes sociales." },
  { number: 10, text: "Planifica tu Próxima Aventura", description: "¡El mundo está lleno de lugares por descubrir! Planifica tu próxima aventura con 4 Rent y prepárate para vivir experiencias inolvidables sobre dos ruedas." }
];

const StepsComponent = () => {
  return (
    <div className="steps-container">
      <div className="steps-banner">
        <div className="banner-contenedor">
          <h2 className="steps-banner-title">Descubre los Pasos para Alquilar una Moto en Bogotá y Medellín</h2>
        </div>
      </div>
      {stepsData.map((step, index) => (
        <div className="step" key={index}>
          <div className="step-number">{step.number}</div>
          <div className="step-info">
            <h3>{step.text}</h3>
            <p>{step.description}</p>
            <hr />
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepsComponent;
