
import fz250_hero from "../assest/Condiciones.svg";
import Condiciones_de_pago from "../assest/Condiciones_de_pago.png";
import Limite_de_kilometraje from "../assest/Límite de kilometraje.png";
import Condiciones_de_entrega from "../assest/Condiciones de entrega.png";
import Reserva_y_cancelacion from "../assest/Reserva y cancelación.png";
import Deposito_con_tarjeta_de_credito from "../assest/Depósito con tarjeta de crédito.png";
import Deposito_en_efectivo from "../assest/Depósito en efectivo.png";
import Recargo_por_Pico_y_Placa from "../assest/Recargo por Pico y Placa.png";
import Horario_de_uso from "../assest/h..png";
import Inicio_del_arrendamiento from "../assest/Inicio del arrendamiento.png";
import No_reembolsos from "../assest/No reembolsos.png";
import Costo_de_entrega_a_domicilio from "../assest/Costo de entrega a domicilio.png";
import Mantenimiento_de_motocicletas from "../assest/Mantenimiento de motocicletas.png";
import './Condiciones4Rent.css';
import Buton from "../components/Button/Button";

function Condiciones4Rent() {
  const condiciones = [
    {
      titulo: 'Condiciones de pago',
      texto: 'El canon y el depósito se recaudan anticipadamente.',
      imagen: Condiciones_de_pago,
    },
    {
      titulo: 'Límite de kilometraje',
      texto: 'El arrendamiento por jornadas incorpora un tope de 200 kilómetros diarios, los cuales son acumulables y utilizables durante el plazo pactado en el convenio.',
      imagen: Limite_de_kilometraje,
    },
    {
      titulo: 'Condiciones de entrega',
      texto: 'Todas las motocicletas se entregan higienizadas y con el tanque lleno, igualmente deben ser devueltas en las mismas condiciones.',
      imagen: Condiciones_de_entrega,
    },
    {
      titulo: 'Reserva y cancelación',
      texto: 'Para realizar una reserva, verifica la disponibilidad y te enviaremos un enlace para efectuar el 20% del arrendamiento (dinero no retornable en caso de anulación).',
      imagen: Reserva_y_cancelacion,
    },
    {
      titulo: 'Depósito con tarjeta de crédito',
      texto: 'Si el depósito se realiza con tarjeta de crédito, el monto se liberará al momento de la restitución de la moto.',
      imagen: Deposito_con_tarjeta_de_credito,
    },
    {
      titulo: 'Depósito en efectivo',
      texto: 'Si el depósito se efectúa en efectivo, se retendrán $600.000 COP durante 8 días y el remanente del depósito se reintegrará al momento de la restitución de la motocicleta.',
      imagen: Deposito_en_efectivo,
    },
    {
      titulo: 'Recargo por Pico y Placa',
      texto: 'Se impondrá un recargo adicional a la tarifa por el uso de motocicletas durante el horario de restricción por PICO Y PLACA, exclusivamente en aquellas ciudades donde esta medida esté vigente.',
      imagen: Recargo_por_Pico_y_Placa,
    },
    {
      titulo: 'Horario de uso',
      texto: 'Por cada día de arrendamiento, dispondrás de 24 horas para utilizar la motocicleta, toma en cuenta nuestros horarios de atención para la devolución.',
      imagen: Horario_de_uso,
    },
    {
      titulo: 'Inicio del arrendamiento',
      texto: 'El periodo de arrendamiento comienza en el momento en que el usuario recibe la motocicleta.',
      imagen: Inicio_del_arrendamiento,
    },
    {
      titulo: 'No reembolsos',
      texto: 'El servicio de arrendamiento de motos no permite reembolsos en caso de devolución anticipada voluntaria por parte del cliente.',
      imagen: No_reembolsos,
    },
    {
      titulo: 'Costo de entrega a domicilio',
      texto: 'El servicio de entrega a domicilio tiene un costo de $69.000 COP dentro del horario regular de 4Rent y de $89.000 COP fuera de dicho horario.',
      imagen: Costo_de_entrega_a_domicilio,
    },
    {
      titulo: 'Mantenimiento de motocicletas',
      texto: 'Los servicios de mantenimiento de nuestras motocicletas se realizan cada 2.000km en los talleres autorizados de la marca, estos mantenimientos son asumidos por 4Rent.',
      imagen: Mantenimiento_de_motocicletas,
    },
  ];

  return (
    <>
  <div className="hero-container" id="condiciones">
  <div className="hero-image" style={{ backgroundImage: `url(${fz250_hero})` }}></div>
  <div className="overlay"></div>
  <div className="hero-content">
    <p className="hero-title">Condiciones 4rent</p>
    <p className="btitle">4 rent, motos para viajar y disfrutar de los lugares más emocionantes de Bogotá.</p>
    <div className="button-container"
    >
      <Buton to="/motos"
        >Ver Motos</Buton>
    </div>
  </div>
</div>


      <div className="content-container">
        <h1 className="content-title">Condiciones para alquilar una moto en 4 rent Bogotá</h1>
        <div className="grid-container">
          {condiciones.map((condicion, index) => (
            <div className="condicion-card" key={index}>
              <h2 className="card-title">{condicion.titulo}</h2>
              <img src={condicion.imagen} alt={condicion.titulo} className="card-image" />
              <p className="card-text">{condicion.texto}</p>
            
            </div>
          ))}
        </div>
      </div>

      <div className="seo-text">
        <h3 className="seo-title">¡Prepárate para vivir una aventura sin límites con 4 Rent en Bogotá!</h3>
        <p className="seo-paragraph">¿Listo para descubrir las emociones que solo una moto puede ofrecerte? Te invitamos a explorar nuestras exclusivas condiciones de alquiler para viajar por Colombia.</p>
        <p className="seo-paragraph">Desde el pago anticipado que garantiza tu reserva hasta el kilometraje flexible de hasta 200 kilómetros diarios, acumulables durante todo tu periodo de alquiler.</p>
        <p className="seo-paragraph">Recibe tu moto limpia y con el tanque lleno, lista para emprender tu viaje, con un proceso de reserva fácil y seguro, ya sea con tarjeta de crédito o efectivo, garantizando transparencia en cada paso.</p>
        <p className="seo-paragraph">Además, cumplimos con las regulaciones locales, como el Pico y Placa, y ofrecemos un horario conveniente de 24 horas por día de alquiler, adaptado a tus necesidades.</p>
        <p className="seo-paragraph">Con inicio sin complicaciones y la opción de servicio a domicilio, tu comodidad es nuestra prioridad.</p>
        <p className="seo-paragraph">Mantenemos tu seguridad con un mantenimiento garantizado en talleres autorizados. ¡Vive la libertad sobre dos ruedas con 4 Rent! Reserva hoy mismo y deja que tus sueños tomen el control.</p>
      </div>
    </>
  );
}

export default Condiciones4Rent;
