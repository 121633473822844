import { useState } from 'react';
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import uploadImage from '../helpers/uploadImage';
import DisplayImage from './DisplayImage';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import productCategory from '../helpers/productCategory';

const UploadProduct = ({ onClose, fetchData }) => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    motor: "",
    cilindrada: "",
    alimentacion: "",
    diametroXCarrera: "",
    potencia: "",
    torqueMaximo: "",
    arranque: "",
    capacidadCombustible: "",
    transmision: "",
    maletero: "No",
    baulCapacidad: "40 Litros",
    planesTarifas: {
      alquilerPorDias: [],
      planesSemanales: [],
      planesQuincenales: [],
      planesMensuales: [],
      adicionales: {
        kilometroAdicionalCOP: 0,
        horaAdicionalCOP: 0,
        depositoFianzaCOP: 0,
      },
    },
  });

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((preve) => ({
      ...preve,
      [name]: value,
    }));
  };

  const handleUploadProduct = async (e) => {
    const file = e.target.files[0];
    const uploadImageCloudinary = await uploadImage(file);

    setData((preve) => ({
      ...preve,
      productImage: [...preve.productImage, uploadImageCloudinary.url],
    }));
  };

  const handleDeleteProductImage = async (index) => {
    const newProductImage = [...data.productImage];
    newProductImage.splice(index, 1);

    setData((preve) => ({
      ...preve,
      productImage: [...newProductImage],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(SummaryApi.uploadProduct.url, {
      method: SummaryApi.uploadProduct.method,
      credentials: 'include',
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (responseData.success) {
      toast.success(responseData?.message);
      onClose();
      fetchData();
    } else if (responseData.error) {
      toast.error(responseData?.message);
    }
  };

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Subir Motocicleta</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
          {/* Campos de texto */}
          <label htmlFor='productName'>Nombre del producto :</label>
          <input
            type='text'
            id='productName'
            placeholder='Ingrese el nombre del producto'
            name='productName'
            value={data.productName}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='brandName' className='mt-3'>Marca :</label>
          <input
            type='text'
            id='brandName'
            placeholder='Ingrese la marca'
            value={data.brandName}
            name='brandName'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='category' className='mt-3'>Categoría :</label>
          <select
            required
            value={data.category}
            name='category'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          >
            <option value="">Seleccionar Categoría</option>
            {productCategory.map((el, index) => (
              <option value={el.value} key={el.value + index}>{el.label}</option>
            ))}
          </select>

          {/* Campos adicionales */}
          <label htmlFor='motor' className='mt-3'>Motor :</label>
          <input
            type='text'
            id='motor'
            placeholder='Ingrese el tipo de motor'
            name='motor'
            value={data.motor}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='cilindrada' className='mt-3'>Cilindrada :</label>
          <input
            type='text'
            id='cilindrada'
            placeholder='Ingrese la cilindrada'
            name='cilindrada'
            value={data.cilindrada}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='alimentacion' className='mt-3'>Alimentación :</label>
          <input
            type='text'
            id='alimentacion'
            placeholder='Ingrese el tipo de alimentación'
            name='alimentacion'
            value={data.alimentacion}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='diametroXCarrera' className='mt-3'>Diámetro x Carrera :</label>
          <input
            type='text'
            id='diametroXCarrera'
            placeholder='Ingrese el diámetro x carrera'
            name='diametroXCarrera'
            value={data.diametroXCarrera}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='potencia' className='mt-3'>Potencia :</label>
          <input
            type='text'
            id='potencia'
            placeholder='Ingrese la potencia'
            name='potencia'
            value={data.potencia}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='torqueMaximo' className='mt-3'>Torque Máximo :</label>
          <input
            type='text'
            id='torqueMaximo'
            placeholder='Ingrese el torque máximo'
            name='torqueMaximo'
            value={data.torqueMaximo}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='arranque' className='mt-3'>Arranque :</label>
          <input
            type='text'
            id='arranque'
            placeholder='Ingrese el tipo de arranque'
            name='arranque'
            value={data.arranque}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='capacidadCombustible' className='mt-3'>Capacidad de Combustible :</label>
          <input
            type='text'
            id='capacidadCombustible'
            placeholder='Ingrese la capacidad de combustible'
            name='capacidadCombustible'
            value={data.capacidadCombustible}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='transmision' className='mt-3'>Transmisión :</label>
          <input
            type='text'
            id='transmision'
            placeholder='Ingrese el tipo de transmisión'
            name='transmision'
            value={data.transmision}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          <label htmlFor='maletero' className='mt-3'>Maletero :</label>
          <select
            name='maletero'
            value={data.maletero}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          >
            <option value="No">No</option>
            <option value="Sí">Sí</option>
          </select>

          <label htmlFor='baulCapacidad' className='mt-3'>Baúl Capacidad :</label>
          <input
            type='text'
            id='baulCapacidad'
            placeholder='Ingrese la capacidad del baúl'
            name='baulCapacidad'
            value={data.baulCapacidad}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
          />

          {/* Planes Tarifas */}
          {/* Alquiler por Días */}
          <label className='mt-3 font-bold'>Planes Tarifas - Alquiler por Días :</label>
          {data.planesTarifas.alquilerPorDias.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Días:</label>
              <input
                type='text'
                name='dias'
                value={plan.dias}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.alquilerPorDias];
                    updatedPlans[index].dias = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        alquilerPorDias: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa COP:</label>
              <input
                type='number'
                name='tarifaCOP'
                value={plan.tarifaCOP}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.alquilerPorDias];
                    updatedPlans[index].tarifaCOP = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        alquilerPorDias: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                value={plan.tarifaUSD}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.alquilerPorDias];
                    updatedPlans[index].tarifaUSD = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        alquilerPorDias: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <button
                type='button'
                onClick={() => {
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.alquilerPorDias];
                    updatedPlans.splice(index, 1);
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        alquilerPorDias: updatedPlans,
                      },
                    };
                  });
                }}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}

          <button
            type='button'
            onClick={() => {
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  alquilerPorDias: [
                    ...prev.planesTarifas.alquilerPorDias,
                    { dias: '', tarifaCOP: 0, tarifaUSD: 0 },
                  ],
                },
              }));
            }}
            className='text-blue-600 mb-2'
          >
            Agregar Plan de Alquiler por Días
          </button>

          {/* Planes Semanales */}
          <label className='mt-3 font-bold'>Planes Tarifas - Planes Semanales :</label>
          {data.planesTarifas.planesSemanales.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Kilómetros:</label>
              <input
                type='text'
                name='kilometros'
                value={plan.kilometros}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesSemanales];
                    updatedPlans[index].kilometros = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesSemanales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa COP:</label>
              <input
                type='number'
                name='tarifaCOP'
                value={plan.tarifaCOP}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesSemanales];
                    updatedPlans[index].tarifaCOP = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesSemanales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                value={plan.tarifaUSD}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesSemanales];
                    updatedPlans[index].tarifaUSD = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesSemanales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <button
                type='button'
                onClick={() => {
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesSemanales];
                    updatedPlans.splice(index, 1);
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesSemanales: updatedPlans,
                      },
                    };
                  });
                }}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}

          <button
            type='button'
            onClick={() => {
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  planesSemanales: [
                    ...prev.planesTarifas.planesSemanales,
                    { kilometros: '', tarifaCOP: 0, tarifaUSD: 0 },
                  ],
                },
              }));
            }}
            className='text-blue-600 mb-2'
          >
            Agregar Plan Semanal
          </button>

          {/* Planes Quincenales */}
          <label className='mt-3 font-bold'>Planes Tarifas - Planes Quincenales :</label>
          {data.planesTarifas.planesQuincenales.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Kilómetros:</label>
              <input
                type='text'
                name='kilometros'
                value={plan.kilometros}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesQuincenales];
                    updatedPlans[index].kilometros = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesQuincenales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa COP:</label>
              <input
                type='number'
                name='tarifaCOP'
                value={plan.tarifaCOP}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesQuincenales];
                    updatedPlans[index].tarifaCOP = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesQuincenales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                value={plan.tarifaUSD}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesQuincenales];
                    updatedPlans[index].tarifaUSD = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesQuincenales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <button
                type='button'
                onClick={() => {
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesQuincenales];
                    updatedPlans.splice(index, 1);
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesQuincenales: updatedPlans,
                      },
                    };
                  });
                }}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}

          <button
            type='button'
            onClick={() => {
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  planesQuincenales: [
                    ...prev.planesTarifas.planesQuincenales,
                    { kilometros: '', tarifaCOP: 0, tarifaUSD: 0 },
                  ],
                },
              }));
            }}
            className='text-blue-600 mb-2'
          >
            Agregar Plan Quincenal
          </button>

          {/* Planes Mensuales */}
          <label className='mt-3 font-bold'>Planes Tarifas - Planes Mensuales :</label>
          {data.planesTarifas.planesMensuales.map((plan, index) => (
            <div key={index} className='border p-2 rounded mb-2'>
              <label>Kilómetros:</label>
              <input
                type='text'
                name='kilometros'
                value={plan.kilometros}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesMensuales];
                    updatedPlans[index].kilometros = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesMensuales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa COP:</label>
              <input
                type='number'
                name='tarifaCOP'
                value={plan.tarifaCOP}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesMensuales];
                    updatedPlans[index].tarifaCOP = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesMensuales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <label>Tarifa USD:</label>
              <input
                type='number'
                name='tarifaUSD'
                value={plan.tarifaUSD}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesMensuales];
                    updatedPlans[index].tarifaUSD = value;
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesMensuales: updatedPlans,
                      },
                    };
                  });
                }}
                className='p-1 bg-slate-100 border rounded w-full'
              />

              <button
                type='button'
                onClick={() => {
                  setData((prev) => {
                    const updatedPlans = [...prev.planesTarifas.planesMensuales];
                    updatedPlans.splice(index, 1);
                    return {
                      ...prev,
                      planesTarifas: {
                        ...prev.planesTarifas,
                        planesMensuales: updatedPlans,
                      },
                    };
                  });
                }}
                className='text-red-600 mt-1'
              >
                Eliminar Plan
              </button>
            </div>
          ))}

          <button
            type='button'
            onClick={() => {
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  planesMensuales: [
                    ...prev.planesTarifas.planesMensuales,
                    { kilometros: '', tarifaCOP: 0, tarifaUSD: 0 },
                  ],
                },
              }));
            }}
            className='text-blue-600 mb-2'
          >
            Agregar Plan Mensual
          </button>

          {/* Adicionales */}
          <label className='mt-3 font-bold'>Adicionales:</label>
          <label>Kilómetro Adicional COP:</label>
          <input
            type='number'
            name='kilometroAdicionalCOP'
            value={data.planesTarifas.adicionales.kilometroAdicionalCOP}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  adicionales: {
                    ...prev.planesTarifas.adicionales,
                    kilometroAdicionalCOP: value,
                  },
                },
              }));
            }}
            className='p-1 bg-slate-100 border rounded w-full'
          />

          <label>Hora Adicional COP:</label>
          <input
            type='number'
            name='horaAdicionalCOP'
            value={data.planesTarifas.adicionales.horaAdicionalCOP}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  adicionales: {
                    ...prev.planesTarifas.adicionales,
                    horaAdicionalCOP: value,
                  },
                },
              }));
            }}
            className='p-1 bg-slate-100 border rounded w-full'
          />

          <label>Depósito/Fianza COP:</label>
          <input
            type='number'
            name='depositoFianzaCOP'
            value={data.planesTarifas.adicionales.depositoFianzaCOP}
            onChange={(e) => {
              const { value } = e.target;
              setData((prev) => ({
                ...prev,
                planesTarifas: {
                  ...prev.planesTarifas,
                  adicionales: {
                    ...prev.planesTarifas.adicionales,
                    depositoFianzaCOP: value,
                  },
                },
              }));
            }}
            className='p-1 bg-slate-100 border rounded w-full'
          />

          {/* Imagen del producto */}
          <label htmlFor='productImage' className='mt-3'>Producto Imagen :</label>
          <label htmlFor='uploadImageInput'>
            <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Subir Imagen del Producto</p>
                <input type='file' id='uploadImageInput' className='hidden' onChange={handleUploadProduct} />
              </div>
            </div>
          </label>
          <div>
            {data?.productImage[0] ? (
              <div className='flex items-center gap-2'>
                {data.productImage.map((el, index) => (
                  <div className='relative group' key={index}>
                    <img
                      src={el}
                      alt={el}
                      width={80}
                      height={80}
                      className='bg-slate-100 border cursor-pointer'
                      onClick={() => {
                        setOpenFullScreenImage(true);
                        setFullScreenImage(el);
                      }}
                    />
                    <div
                      className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                      onClick={() => handleDeleteProductImage(index)}
                    >
                      <MdDelete />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-red-600 text-xs'>*Por favor sube una imagen del producto</p>
            )}
          </div>

          <label htmlFor='price' className='mt-3'>Precio :</label>
          <input
            type='number'
            id='price'
            placeholder='Ingrese el precio'
            value={data.price}
            name='price'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='sellingPrice' className='mt-3'>Precio de venta :</label>
          <input
            type='number'
            id='sellingPrice'
            placeholder='Ingrese el precio de venta'
            value={data.sellingPrice}
            name='sellingPrice'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='description' className='mt-3'>Descripción :</label>
          <textarea
            className='h-28 bg-slate-100 border resize-none p-1'
            placeholder='Ingrese la descripción del producto'
            rows={3}
            onChange={handleOnChange}
            name='description'
            value={data.description}
          ></textarea>

          <button className='px-3 py-2 bg-red-600 text-white mb-10 hover:bg-red-700'>Subir Producto</button>
        </form>
      </div>

      {/* Mostrar imagen en pantalla completa */}
      {openFullScreenImage && (
        <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
      )}
    </div>
  );
};

export default UploadProduct;
