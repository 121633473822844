import React, { useState } from 'react';
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import { toast } from 'react-toastify';
import SummaryApi from '../common/index';

const AdminProductCard = ({ data, fetchdata }) => {
    const [editProduct, setEditProduct] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false); // Estado de carga para eliminación

    // Función para eliminar un producto
    const handleDeleteProduct = async () => {
        const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este producto?");
        if (!confirmDelete) return;

        setLoadingDelete(true); // Mostrar que se está procesando la eliminación

        try {
            // Verificar que el ID del producto esté presente
            if (!data?._id) {
                toast.error("ID de producto no disponible");
                setLoadingDelete(false);
                return;
            }

            // Enviar la solicitud DELETE al backend con el ID del producto y las credenciales (cookies)
            const response = await fetch(`${SummaryApi.deleteProduct.url}/${data._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Incluir las cookies en la solicitud
            });

            const responseData = await response.json(); // Convertir la respuesta en JSON

            if (response.ok) {
                toast.success("Producto eliminado con éxito");
                fetchdata(); // Actualizar la lista de productos después de eliminar
            } else {
                console.error('Error del servidor:', responseData);
                toast.error(responseData.message || "Error al eliminar el producto");
            }
        } catch (error) {
            toast.error("Error al eliminar el producto");
            console.error("Error eliminando el producto:", error);
        } finally {
            setLoadingDelete(false); // Restaurar el estado de carga
        }
    };

    return (
        <div className='bg-white p-4 rounded'>
            <div className='w-40'>
                <div className='w-32 h-32 flex justify-center items-center'>
                    {data?.productImage?.[0] ? (
                        <img 
                            src={data?.productImage[0]} 
                            className='mx-auto object-fill h-full' 
                            alt={data?.productName || 'Producto'} 
                        />
                    ) : (
                        <p>No Image Available</p>
                    )}
                </div>
                <h1 className='text-ellipsis line-clamp-2'>{data?.productName}</h1>

                <div>
                    <p className='font-semibold'>
                        {displayINRCurrency(data.sellingPrice)}
                    </p>

                    <div className='flex justify-between mt-2'>
                        {/* Botón de editar */}
                        <div 
                            className='p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' 
                            onClick={() => setEditProduct(true)}
                        >
                            <MdModeEditOutline />
                        </div>

                        {/* Botón de eliminar */}
                        <div 
                            className={`p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer ${loadingDelete ? 'opacity-50 cursor-not-allowed' : ''}`} 
                            onClick={!loadingDelete ? handleDeleteProduct : null} // Deshabilitar si está cargando
                        >
                            <MdDelete />
                        </div>
                    </div>
                </div>
            </div>

            {editProduct && (
                <AdminEditProduct 
                    productData={data} 
                    onClose={() => setEditProduct(false)} 
                    fetchdata={fetchdata} 
                />
            )}
        </div>
    );
};

export default AdminProductCard;
