import  { useContext, useEffect, useRef, useState } from 'react';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/addToCart';
import Context from '../context';
import './HorizontalCardProduct.css'; // Asegúrate de importar el archivo CSS

const HorizontalCardProduct = ({ category, heading }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const loadingList = new Array(13).fill(null);
    const scrollElement = useRef();
    const { fetchUserAddToCart } = useContext(Context);

    const handleAddToCart = async (e, id) => {
        e.preventDefault(); // Evita que el enlace <Link> se dispare al hacer clic en "Add to Cart"
        await addToCart(e, id);
        fetchUserAddToCart();
    };

    const fetchData = async () => {
        setLoading(true);
        const categoryProduct = await fetchCategoryWiseProduct(category);
        setLoading(false);

        if (categoryProduct && categoryProduct.data) {
            setData(categoryProduct.data);
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, [category]);

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300;
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300;
    };

    return (
        <div className="container">
            <h2>{heading}</h2>
            <div className="card-container" ref={scrollElement}>
                <button className="scroll-button left" onClick={scrollLeft}>
                    <FaAngleLeft />
                </button>
                <button className="scroll-button right" onClick={scrollRight}>
                    <FaAngleRight />
                </button>
                
                {loading ? (
                    loadingList.map((_, index) => (
                        <div key={index} className="card loading">
                            <div className="card-content">
                                <div className="bg-slate-200 h-full"></div>
                                <h2 className="animate-pulse"></h2>
                                <p className="animate-pulse"></p>
                                <div className="flex gap-3">
                                    <p className="animate-pulse"></p>
                                    <p className="animate-pulse price-line-through"></p>
                                </div>
                                <button className="button animate-pulse"></button>
                            </div>
                        </div>
                    ))
                ) : (
                    data.map((product) => (
                        <div key={product._id} className="card">
                            <Link to={`product/${product._id}`} className="card-link">
                                <img src={product.productImage[0]} alt={product.productName || 'Product Image'} />
                                <div className="card-content">
                                    <h2>{product.productName}</h2>
                                    <p className="capitalize">{product.brandName}</p>
                                    <div className="flex gap-3">
                                        <p className="price">{displayINRCurrency(product.sellingPrice)}</p>
                                        <p className="price-line-through">{displayINRCurrency(product.price)}</p>
                                    </div>
                                    <div className="flex justify-between items-center mt-auto">
                                        {/* El botón de favoritos se ha eliminado de aquí */}
                                        <button 
                                            className="button" 
                                            onClick={(e) => handleAddToCart(e, product._id)}
                                        >
                                            Envío gratis
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default HorizontalCardProduct;
