
import fz250_hero from "../assest/requisitos-4rent.png";
import './Requisitos4Rent.css'; 
import Buton from "../components/Button/Button"; 
function Requisitos4Rent() {
  return (
    <>
      <div className="requisitos-hero" id='requisitos'>
        <div
          className="requisitos-hero-bg"
          style={{ backgroundImage: `url(${fz250_hero})` }}
        />
        <div className="requisitos-hero-overlay" />
        <div className="requisitos-hero-text">
          <p className="hero-title">Requisitos 4rent</p>
          <div className="requisitos-hero-link">
            <Buton to="/motos">
              <p>Ver Motos</p>
            </Buton>
          </div>
        </div>
      </div>

      <div className="requisitos-content">
        <h1>Requisitos para rentar una moto en Bogotá 4rent:</h1>
        <div className="requisitos-grid">
          <div>
            <h2>Documentos necesarios:</h2>
            <ul>
              <li>Presentar un documento de identificación válido (Pasaporte para Extranjeros).</li>
              <li>Presentar físicamente una licencia vigente para conducir motocicleta. (Si aceptamos licencias de moto de otros países).</li>
            </ul>
          </div>
          <div>
            <h2>Requisitos adicionales:</h2>
            <ul>
              <li>Tener 23 años o más.</li>
              <li>Tener experiencia en la conducción de motocicletas.</li>
              <li>Disponer de un depósito: Puede ser en efectivo, tarjeta de crédito o con PayPal.</li>
            </ul>
          </div>
        </div>
        <p className="requisitos-footer">
          Cumplir con estos requisitos garantizará un proceso de alquiler eficiente y sin contratiempos. 4Rent ofrece un servicio seguro y confiable para que puedas disfrutar de tu experiencia de conducción con tranquilidad.
        </p>
      </div>
    </>
  );
}

export default Requisitos4Rent;
