import  { useContext } from 'react'
import scrollTop from '../helpers/scrollTop'
import displayINRCurrency from '../helpers/displayCurrency'
import Context from '../context'
import addToCart from '../helpers/addToCart'
import { Link } from 'react-router-dom'
import './VerticalCard.css'; // Importar el archivo CSS
import BotonFavorito from './BotonFavorito'; // Importa el botón de favorito
const VerticalCard = ({loading, data = []}) => {
    const loadingList = new Array(13).fill(null)
    const { fetchUserAddToCart } = useContext(Context)

    const handleAddToCart = async (e, id) => {
        await addToCart(e, id)
        fetchUserAddToCart()
    }

    return (
        <div className="vertical-card-grid">
            {
                loading ? (
                    loadingList.map((_, index) => {
                        return (
                            <div key={index} className="vertical-card loading">
                                <div className="product-image-placeholder"></div>
                                <div className="product-info">
                                    <h2 className="loading-placeholder">.</h2>
                                    <p className="loading-placeholder"></p>
                                    <div className="price-placeholder"></div>
                                    <button className="loading-placeholder"></button>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    data.map((product, index) => {
                        return (
                            <Link key={index} to={`/product/${product?._id}`} className="vertical-card" onClick={scrollTop}>
                                <div className="product-image">
                                    <img src={product?.productImage[0]} className="product-image-content" alt={product?.productName || 'Product Image'} />
                                </div>
                                <div className="product-info">
                                    <h2 className="product-name">{product?.productName}</h2>
                                    <p className="product-category">{product?.category}</p>
                                    <div className="product-prices">
                                        <p className="product-selling-price">{displayINRCurrency(product?.sellingPrice)}</p>
                                        <p className="product-original-price">{displayINRCurrency(product?.price)}</p>
                                    </div>
                                    <BotonFavorito productId={product._id} /> {/* Botón de favorito */}
                                    <button className="add-to-cart-btn" onClick={(e) => handleAddToCart(e, product?._id)}>añadir a reserva</button>
                                </div>
                            </Link>
                        )
                    })
                )
            }
        </div>
    )
}

export default VerticalCard
