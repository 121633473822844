import { useEffect } from "react";
import PropTypes from "prop-types";
import './Rentar.css'; // Importar el archivo CSS local
import AOS from "aos";
import "aos/dist/aos.css";
import fz250_hero from "../assest/morcycle_girl.png";
import Button from "../components/Button/Button"; // Corregí "Buton" por "Button"

const Rentar = () => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="hero">
        <div
          className="hero-image"
          style={{ backgroundImage: `url(${fz250_hero})` }}
        ></div>
        <div className="overlay"></div>
        <div className="content">
          <h1 className="hero-title">4 Rent</h1>
          <p className="btitle">
            Renta tu motocicleta ahora mismo en 4rent.com.co
          </p>
          <div className="cta-button">
            <Button to="/motos">Ver Motos</Button>
          </div>
        </div>
      </div>

      <div className="container_one">
        <div className="grid_one">
          <div className="section_one">
            <h2 className="section-title_one">
              ¡Descubre Bogotá en Moto y Vive una Aventura Inolvidable!
            </h2>
            <p className="section-text_one">
              ¿Estás listo para explorar Bogotá de una manera emocionante y
              única? Renta una motocicleta con nosotros y descubre cada rincón
              de esta hermosa ciudad colombiana con total libertad y estilo.
            </p>
            <p className="section-text_one">
              En nuestra empresa de renta de motos en Bogotá, ofrecemos una
              amplia gama de modelos para satisfacer tus necesidades y
              preferencias. Ya sea que busques adrenalina con una moto deportiva
              o prefieras relajarte con una moto cruiser, ¡tenemos la moto
              perfecta para ti!
            </p>
            <p className="section-text_one">
              Todas nuestras motocicletas están en excelentes condiciones y
              reciben un mantenimiento riguroso para garantizar tu seguridad y
              comodidad en cada viaje. Además, proporcionamos cascos y equipos
              de protección para tu tranquilidad y seguridad en la carretera.
            </p>
            <p className="section-text_one">
              ¡No esperes más para vivir esta experiencia única! Ponte en
              contacto con nosotros hoy mismo para rentar una motocicleta y
              comenzar tu aventura por las calles de Bogotá. Nuestro equipo
              amable y profesional estará encantado de ayudarte en cada paso del
              proceso de renta.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

Rentar.propTypes = {
  phoneNumbertree: PropTypes.string.isRequired,
};

export default Rentar;
