import React, { createContext, useState, useEffect, useCallback } from 'react';
import SummaryApi from '../common';

const Context = createContext(null);

export const ContextProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [cartItems, setCartItems] = useState([]);

    // Función para obtener los detalles del usuario
    const fetchUserDetails = useCallback(async () => {
        try {
            const response = await fetch(SummaryApi.auth.currentUser.url, {
                method: SummaryApi.auth.currentUser.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
            console.log('Respuesta de fetchUserDetails:', data);
    
            if (response.ok && data.success) {
                setUserDetails(data.data);
                console.log('Detalles del usuario actualizados:', data.data);
            } else {
                console.error('Error al obtener los detalles del usuario:', data.message);
                setUserDetails(null);
            }
        } catch (error) {
            console.error('Error al obtener los detalles del usuario:', error);
            setUserDetails(null);
        }
    }, []);
    


    // Función para obtener los ítems del carrito
    const fetchUserAddToCart = useCallback(async () => {
        try {
            const response = await fetch(SummaryApi.cart.view.url, {
                method: SummaryApi.cart.view.method,
                credentials: 'include', // Incluye las cookies en la solicitud
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            console.log('fetchUserAddToCart response:', data); // Log

            if (response.ok && data.success) {
                setCartItems(data.data);
            } else {
                console.error('Error al obtener los ítems del carrito:', data.message);
                setCartItems([]);
            }
        } catch (error) {
            console.error('Error al obtener los ítems del carrito:', error);
            setCartItems([]);
        }
    }, []);

    // Efecto para obtener los detalles del usuario y los ítems del carrito al montar el componente
    useEffect(() => {
        fetchUserDetails();
        fetchUserAddToCart();
    }, [fetchUserDetails, fetchUserAddToCart]);

    return (
        <Context.Provider
            value={{
                userDetails,
                setUserDetails,
                cartItems,
                setCartItems,
                fetchUserDetails,
                fetchUserAddToCart,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default Context;
