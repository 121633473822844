import React, { useEffect, useState } from 'react';
import image1 from '../assest/banner/img1.png';
import image2 from '../assest/banner/img2.png';
import image3 from '../assest/banner/img3.png';
import image4 from '../assest/banner/img4.png';


import image1Mobile from '../assest/banner/img1_mobile.jpg';
import image2Mobile from '../assest/banner/img2_mobile.jpg';


import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import './BannerProduct.css'; // Asegúrate de importar el archivo CSS

const BannerProduct = () => {
    const [currentImage, setCurrentImage] = useState(0);

    const desktopImages = [
        image1,
        image2,
        image3,
        image4,

    ];

    const mobileImages = [
        image1Mobile,
        image2Mobile,
        image1Mobile,
        image2Mobile,
    ];

    const nextImage = () => {
        if (desktopImages.length - 1 > currentImage) {
            setCurrentImage(prev => prev + 1);
        }
    };

    const prevImage = () => {
        if (currentImage !== 0) {
            setCurrentImage(prev => prev - 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (desktopImages.length - 1 > currentImage) {
                nextImage();
            } else {
                setCurrentImage(0);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImage]);

    return (
        <div className='banner-container'>
            <div className='banner-content'>
                <div className='navigation-buttons'>
                    <button onClick={prevImage} className='nav-button'><FaAngleLeft /></button>
                    <button onClick={nextImage} className='nav-button'><FaAngleRight /></button>
                </div>

                {/**desktop and tablet version */}
                <div className='desktop-images'>
                    {
                        desktopImages.map((imageUrl, index) => (
                            <div className='image-wrapper' key={imageUrl} style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                                <img src={imageUrl} className='image' alt={`banner ${index}`} />
                            </div>
                        ))
                    }
                </div>

                {/**mobile version */}
                <div className='mobile-images'>
                    {
                        mobileImages.map((imageUrl, index) => (
                            <div className='image-wrapper' key={imageUrl} style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                                <img src={imageUrl} className='image' alt={`banner mobile ${index}`} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default BannerProduct;
