// src/pages/Cart.js

import { useDispatch, useSelector } from 'react-redux';
import displayINRCurrency from '../helpers/displayCurrency';
import { MdDelete } from 'react-icons/md';
import './Cart.css';
import { removeFromCart } from '../store/cartSlice';
import { useNavigate } from 'react-router-dom';
import PayUForm from '../components/PayUForm'; // Importar el nuevo componente
import {  useEffect } from 'react';

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems); // Corregido

  // Logs para depuración
  useEffect(() => {
    console.log('cartItems:', cartItems);
  }, [cartItems]);

  // Calcular el total del carrito
  const calculateTotal = () => {
    if (!Array.isArray(cartItems)) {
      console.error('cartItems no es un array:', cartItems);
      return 0;
    }

    let total = 0;
    cartItems.forEach((item) => {
      if (item && item.reservationDetails && typeof item.reservationDetails.totalPrice === 'number') {
        total += item.reservationDetails.totalPrice;
      } else {
        console.warn('Item inválido en cartItems:', item);
      }
    });
    return total;
  };

  const totalAmount = calculateTotal();
  const paymentAmount = totalAmount * 0.10; // 10% del total

  // Función para eliminar un producto del carrito
  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart({ productId }));
  };

  return (
    <div className="cart-container">
      {(!cartItems || cartItems.length === 0) ? (
        <p className="empty-cart">No hay motos en la reserva.</p>
      ) : (
        <div className="cart-content">
          {/* Lista de productos */}
          <div className="product-list">
            {cartItems.map((item, index) => (
              <div key={index} className="product-card">
                <div className="product-image">
                  <img
                    src={item.reservationDetails.productImage || 'ruta/por/defecto.jpg'}
                    alt={item.reservationDetails.productName || 'Producto'}
                  />
                  <div
                    className="delete-button"
                    onClick={() => handleRemoveFromCart(item.productId)}
                  >
                    <MdDelete />
                  </div>
                </div>
                <div className="product-details">
                  <h2 className="product-name">{item.reservationDetails.productName || 'Producto'}</h2>
                  <p className="product-category">
                    {item.reservationDetails.category || 'Categoría desconocida'}
                  </p>
                  {/* Mostrar los detalles de la reserva */}
                  <div className="reservation-details">
                    <p><strong>Ciudad:</strong> {item.reservationDetails.city || 'No especificado'}</p>
                    <p><strong>Plan:</strong> {item.reservationDetails.planType || 'No especificado'}</p>
                    <p><strong>Opción del Plan:</strong> {item.reservationDetails.planOption || 'No especificado'}</p>
                    <p><strong>Fecha de Inicio:</strong> {item.reservationDetails.startDate || 'No especificado'}</p>
                    <p><strong>Hora de Inicio:</strong> {item.reservationDetails.startTime || 'No especificado'}</p>
                    <p><strong>Fecha de Fin:</strong> {item.reservationDetails.endDate || 'No especificado'}</p>
                    <p><strong>Hora de Fin:</strong> {item.reservationDetails.endTime || 'No especificado'}</p>
                    <p><strong>Productos Adicionales:</strong> {item.reservationDetails.additionalProducts?.join(', ') || 'Ninguno'}</p>
                  </div>
                  <div className="product-price">
                    <p className="total-price">
                      Total: {displayINRCurrency(item.reservationDetails.totalPrice || 0)} COP
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Mostrar el total general */}
          <div className="cart-total">
            <h3>Total a Pagar: {displayINRCurrency(paymentAmount)} COP</h3>
            <p className="payment-note">Se paga el 10% del total por reserva.</p>
          </div>

          {/* Componente de Pago */}
          <PayUForm /> {/* Utilizar el nuevo componente */}
        </div>
      )}
    </div>
  );
};

export default Cart;
