import fz250_hero from "../assest/seguros-4rent.png";
import './Seguros.css'; 
import Buton from "../components/Button/Button";
const Seguros = () => {
  return (
    <>
      <div className="hero-section">
        <div className="hero-image" style={{ backgroundImage: `url(${fz250_hero})` }}></div>
        <div className="overlay"></div>
        <div className="content">
          <h1 className="hero-title">Seguros 4 Rent</h1>
          <p className="btitle">Todas nuestras motos están aseguradas.</p>
          <div className="cta-button">
            <Buton to="/motos">Ver Motos</Buton>
          </div>
        </div>
      </div>

      <div className="content-section">
        <div className="container">


          <h1 className="section-title" style={{ color: 'black' }} >Descubre los seguros disponibles para nuestras motos en 4rent</h1>
          <div className="grid">
            <div className="card">
              <h2 className="card-title">Seguro Obligatorio de Accidentes de Tránsito “SOAT”</h2>
              <p className="card-text">
                El SOAT en Colombia es un seguro obligatorio que cubre los gastos médicos y otros costos asociados a lesiones por accidentes de tránsito, así como los gastos de sepelio en caso de fallecimiento. Está diseñado para proteger a todas las personas en territorio colombiano, sin importar su rol en el accidente, proporcionando una cobertura básica para atención médica y otros gastos relacionados con accidentes viales.
              </p>
              <p className="card-text">
                En 4Rent nos aseguramos de que todas nuestras motocicletas estén respaldadas por el SOAT, lo cual brinda una cobertura adicional junto con nuestro seguro todo riesgo.
              </p>
            </div>
            <div className="card">
              <h2 className="card-title">Pólizas todo riesgo en 4Rent:</h2>
              <p className="card-text">
                En 4Rent, nos enfocamos en brindar una cobertura completa y confiable a nuestros clientes a través de nuestras pólizas todo riesgo. Estas pólizas están diseñadas para proteger contra daños totales y robos, asegurando que nuestros clientes estén respaldados en situaciones críticas.
              </p>
              <p className="card-text">
                Además de esta cobertura esencial, también proporcionamos servicios de grúa a nivel nacional para garantizar la asistencia rápida en caso de averías o emergencias en la carretera. Nuestro compromiso con la seguridad y comodidad de nuestros clientes se refleja en la diversidad de asistencias que ofrecemos, adaptadas a diferentes necesidades y situaciones, proporcionadas por nuestros proveedores de confianza.
              </p>
              <p className="card-text">
                Es importante destacar que, si bien nuestras pólizas todo riesgo son amplias, es necesario tener en cuenta que no cubrimos <strong>pérdidas parciales o daños parciales.</strong> Esta aclaración es fundamental para que nuestros clientes estén informados y puedan tomar decisiones informadas sobre su protección y seguridad mientras disfrutan de nuestros servicios de alquiler de motocicletas.
              </p>
            </div>
          </div>
          <div className="cta-container">
            <Buton to="/contacto">¡Alquila Ahora!</Buton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seguros;
